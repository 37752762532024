import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconQuote(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.405 20.604"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M4.635.589a6.33 6.33 0 0 0-2.931 2.2 7.43 7.43 0 0 0-1.455 3.3 49.282 49.282 0 0 0-.25 6.171v8.344h10.373v-9.793H6.485a17.154 17.154 0 0 1 .27-3.407 2.763 2.763 0 0 1 1.081-1.53 4.314 4.314 0 0 1 2.536-.617V-.001a19.4 19.4 0 0 0-5.737.589Zm14.053 0a6.33 6.33 0 0 0-2.931 2.2 7.432 7.432 0 0 0-1.455 3.3 49.3 49.3 0 0 0-.249 6.171v8.344h10.352v-9.793h-3.867a16.853 16.853 0 0 1 .25-3.407 2.625 2.625 0 0 1 1.1-1.53 4.271 4.271 0 0 1 2.515-.617V-.001a19.306 19.306 0 0 0-5.717.589Z"
      />
    </svg>
  );
}
