import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from '../../styles/Highlight.module.css';

interface Props {
  children?: ReactNode;
  fullWidth?: boolean;
  title?: string;
  body?: string;
  size?: 'base' | 'xl';
  className?: string;
}

export default function Highlight({
  children,
  title,
  body,
  fullWidth = false,
  size,
  className,
}: Props) {
  return (
    <div
      className={cn(
        styles.wrap,
        fullWidth ? styles.full : undefined,
        size ? styles[size] : styles.base,
        className
      )}
    >
      {title && <div className={styles.title}>{title}</div>}
      {body && <div className={styles.body}>{body}</div>}
      {children}
    </div>
  );
}
