import type { TestimonialStoryblok } from 'component-types-sb';

import styles from '../../styles/TestimonialCard.module.css';
import IconQuote from '../icons/IconQuote';

export default function TestimonialCard({
  title,
  intro,
  author,
  company,
}: TestimonialStoryblok) {
  return (
    <div className={styles.testimony}>
      <div className={styles.title}>
        <IconQuote width={24} height={24} />
        <div>{title}</div>
      </div>
      <div className={styles.body}>{intro}</div>
      <div className={styles.author}>{author}</div>
      {company && <div className={styles.company}>{company}</div>}
    </div>
  );
}
